
import { IAlert, ResultDatas, RouterName } from "@/models/common";
import router from "@/router";
import $API from "@/services";
import { useStore } from "@/store";
import { CommonMutationTypes } from "@/store/modules/common/mutations";
import { useI18n } from "vue-i18n";
import { defineComponent, onMounted, reactive, ref, Ref } from "vue";
import { messageType } from "@/models/message";

export default defineComponent({
  name: "Confirm Email",
  setup() {
    const { t } = useI18n({ useScope: "global" });

    const state = reactive({
      userId: router.currentRoute.value.query?.userId,
      code: router.currentRoute.value.query?.code,
      message: t("CheckingItNow"),
    });

    const store = useStore();

    const alert: Ref<IAlert> = ref({
      type: messageType.ERROR,
      content: "",
    });

    onMounted(async () => {
      store.commit(CommonMutationTypes.SET_IS_LODING, true);
      if (
        Array.isArray(state.userId) ||
        !state.userId ||
        Array.isArray(state.code) ||
        !state.code
      ) {
        alert.value = {
          content: "Error",
          type: messageType.ERROR,
        };
        return;
      }

      const res = await $API.SignService.confirmEmail(state.userId, state.code);
      const { data } = res;
      if (
        data.ResultData === ResultDatas.SUCCESS ||
        data.ResultData === "Confirming Email"
      ) {
        alert.value = {
          content: "메일인증이 확인되었습니다.",
          type: messageType.INFO,
          callback: () => {
            router.push(RouterName.SIGNIN);
          },
        };
      } else {
        state.message = "확인 불가";
        if (data.ResultData === "SignupFaild") {
          alert.value = {
            content: "회원 가입 실패",
            type: messageType.ERROR,
          };
        } else if (data.ResultData === "Unable to load user") {
          alert.value = {
            content: "회원 가입 실패",
            type: messageType.ERROR,
          };
        } else if (data.ResultData === "Error confirming email") {
          alert.value = {
            content: "이메일 확인 불가",
            type: messageType.ERROR,
          };
        }
      }

      store.commit(CommonMutationTypes.SET_IS_LODING, false);
    });

    return { state, alert };
  },
  methods: {
    /**
     * alert event 확인 시 home으로 이동
     */
    clearMessage() {
      this.$router.push("/");
    },
  },
  components: {},
});
