import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "main__section confirm-email" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")

  return (_openBlock(), _createBlock("section", _hoisted_1, [
    (!!_ctx.state.code && !!_ctx.state.userId)
      ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
          (!!_ctx.alert.content)
            ? (_openBlock(), _createBlock(_component_Alert, {
                key: 0,
                alert: _ctx.alert,
                isConfirm: true,
                "onUpdate:clear": _cache[1] || (_cache[1] = 
          () => {
            _ctx.alert.content = '';
          }
        )
              }, null, 8, ["alert"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}